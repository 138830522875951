<template>
  <div />
</template>
<script>
  import LocalStorageService from '../utils/auth'
  import { mapMutations, mapGetters, mapActions } from 'vuex'
  import axios from 'axios'

  // LocalstorageService
  const localStorageService = LocalStorageService.getService()
  export default {
    name: '',
    computed: mapGetters(['isLoggedIn']),
    mounted () {
      this.$nextTick(() => {
        localStorageService.setToken()

        const token = localStorageService.getAccessToken()
        // console.log('token ', token)
        if (token === null) {
          this.$store.commit('auth_error')
          localStorageService.getErrorMessage()
          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: localStorageService.getErrorMessage(),
            timer: 5000,
          })
          this.$router.push('/pages/login')
        } else {
          this.$store.dispatch('loginCallback', { token: token, user: localStorageService.getUsername() })
            .then(() => {
              this.$router.push('/')
            }).catch(() => {})
        }
      })
    },
    methods: {
      ...mapMutations(['auth_success']),
      ...mapActions(['loginCallback']),

      async getAvatar () {
        // console.log('getAvatar')
        await axios.get('/v2/api/users/profile', { withCredentials: false })
          .then((response) => {
            // console.log('get avatar response', typeof (response.data.data.avatar))
            const avatar = 'data:image/png;base64,' + response.data.data.avatar
            localStorageService.setAvatar(avatar)
          }).catch(e => {
            if (e.response === undefined) {
              this.show_message('error', 'api service not available!')
            } else if (!e.response.data.success) {
              if (e.response.data.validations) {
                this.show_message('error', e.response.data.validations[0].message)
              } else {
                this.show_message('error', e.response.data.message)
              }
            } else {
              this.show_message('error', 'api service not available!')
            }
          })
      },

      show_message (type, text) {
        // type = 'success', 'info', 'error' ... color string
        this.$swal.fire({
          icon: type,
          title: type,
          text: text,
          timer: 5000,
        })
      },
    },
  }
</script>
